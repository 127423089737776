<template>
  <!-- Content -->
  <div v-if="items && !isLoading" class="d-flex flex-column align-items-center">
    <h1>
      <span class="text-primary">{{ $t('onboarding.subscriptions.plans') }}</span>
    </h1>
    <p class="mt-1 font-medium-3 mb-3">
      <span class="text-secondary">{{ $t('onboarding.subscriptions.healine') }}</span>
    </p>
    <b-row>
      <!-- ** ORDER ** -->
      <b-col v-for="item in items" :key="item.key" class="flex-column d-flex align-items-center">
        <b-card no-body class="card-standard" style="cursor:auto;">
          <b-card-body class="flex-column align-items-center justify-content-center">
            <!-- Element Name -->
            <h2 class="mt-2 text-center title" :class="item.headline?'':'mb-2'">
              {{ translate(item.name) }}
            </h2>
            <p v-if="item.headline" class="mb-1 text-center text-secondary">
              {{ translate(item.headline) }}
            </p>

            <div class="text-primary d-flex align-items-center justify-content-center price-container">
              <text-resizable
                :text-style="'text-primary text-center h5'"
                :text="item.total != null && !isEnterprise(item) ? parseFloat(item.total).toLocaleString(currentLocale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3
                }) + item.currencySymbol : translateTranslationTable(currentLocale, enterprisePrice)"
                :default-font-size="3"
                :default-text-length="7"
                :font-size-to-rest="0.30"
              />

              <div div class="d-flex ml-50 flex-column">
                <small v-if="item.interval === 'month'">
                  / <span v-if="item.intervalCount&&item.intervalCount>1">{{ item.intervalCount }} {{ $t('onboarding.subscriptions.months') }}</span><span v-else>{{ $t('onboarding.subscriptions.month') }}</span>
                </small>
                <small v-if="item.interval === 'year'">
                  / {{ $t('onboarding.subscriptions.year') }}
                </small>
              </div>
            </div>
            <div v-if="item.description" class="min-height-5">
              <p class="text-muted small mb-25 mt-2">
                {{ $t('onboarding.subscriptions.includes') }}:
              </p>
              <div v-for="(benefit, index) in translate(item.description).split('\n')" :key="index" class="d-flex">
                <b-icon-check2 variant="primary" font-scale="0.8" class="mr-50 check mt-25" />
                <div class="text-secondary font-small-2 m-0">
                  {{ benefit }}
                </div>
              </div>
            </div>
            <div v-else class="min-height-5">
              <p />
            </div>
            <div
              class="w-100 mt-2 d-flex
             justify-content-between"
            >
              <b-button class="mx-3 mt-1 w-100" variant="primary" @click="translateTranslationTable(currentLocale, item.name).includes('ENTERPRISE') ? handleContact() : goToOrderPurchase(item)">
                {{ translateTranslationTable(currentLocale, item.name).includes('ENTERPRISE') ? $t('products.contact.action') : $t('onboarding.subscriptions.subscribe') }}
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
     <!-- Contact modal -->
     <b-modal
      v-model="contactModalOpened"
      hide-footer
      hide-header
      centered
    >
      <div class="my-4">
        <span
          class="b-avatar shadow mb-2 badge-primary rounded-circle bg-light-primary d-block mx-auto"
          style="width: 50px; height: 50px;"
        >
          <b-icon icon="envelope"  font-scale="2" class="mt-75"></b-icon>
        </span>
        <h4 class="text-center font-weight-normal">
          {{$t('subcommunities.contact.contact-message')}}
        </h4>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import TextResizable from '@/backoffice/components/TextResizable.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'OnboardingPlans',
  components: {
    TextResizable,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: true,
      intervalMonth: true,
      contactModalOpened: false,

    };
  },
  computed: {
    items() {
      if (this.$store.getters.subscriptionPlans != null) {
        return this.$store.getters.subscriptionPlans.unpaginated;
      }
      return [];
    },
    hasSubscription() {
      return !!this.$store.getters.communities?.toPay?.unpaginated?.[0]?.hasSubscription || false;
    },
    Placeholder() {
      return Placeholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    communitySlug() {
      return this.$route.params.community;
    },
    community() {
      return this.$store.getters.communities?.toPay?.unpaginated?.[0];
    },
    enterprisePrice() {
      return {
        ca: 'Parlem',
        en: 'Let\'s talk',
        es: 'Hablamos',
      };
    },
  },
  async created() {
    this.isLoading = true;
    await this.getCommunity();
    if (this.community?.subscription && Object.values(this.community?.subscription)?.includes("Copernic Community")) {
      window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.community?.subdomain || 'app',
      ).replace('{slug}', this.community?.slug)}/backoffice`;
    } else if (this.hasSubscription) {
      this.goToError();
      return;
    }
    await this.fetchData();
  },

  methods: {
    isEnterprise(item) {
      return translateTranslationTable(this.currentLocale, item.name).includes('ENTERPRISE');
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field) || '';
    },
    goToOrderPurchase(item) {
      return this.$router.replace({
        name: 'my-checkout',
        params: { community: this.communitySlug, id: item.key },
      });
    },
    goToError() {
      return this.$router.replace({
        name: 'my-error',
      });
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch('getItemsPayments', {
        itemType: 'subscriptionPlans',
        page: 1,
        requestConfig: {
          orderBy: -1,
          isForNectios: true,
        },
      });
      this.isLoading = false;
    },
    async getCommunity() {
      await this.$store.dispatch('getItems', {
        itemType: 'communities',
        storedKey: 'toPay',
        customName: 'communities',
        communitySlug: this.communitySlug,
      });
    },
    async handleContact() {
      try {
        await this.$store.dispatch('postItem', {
          type: 'backoffice/mailToSales',
          requestConfig: {
            ...(this.$route.name === 'my-plans' ? { fromMyNectios: true } : { memberKey: this.$store.getters.loggedMember.key}),
            ...(this.$route.name === 'my-plans' ? { communitySlug: this.$route.params.community } : ''),
          },
        });
        this.contactModalOpened = true;
        setTimeout(() => {
          this.contactModalOpened = false;
        }, 3000);
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    translateTranslationTable,
  },
};
</script>
<style lang="scss" scoped>
.price-container {
  min-height: 65.8px;
}
</style>
